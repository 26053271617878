<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1
                class="display-3 font-weight-bold mb-2"
                style="font-family: 'Lobster', cursive !important"
              >
                Toucan
              </h1>
              <img
                src="@/assets/toucan.svg"
                style="height:44px;"
                class="white rounded-circle"
              >
              <h1 class="display-2  mb-2">
                {{ $t('connexion.connexion') }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <!-- <div class="text-center grey--text body-1 font-weight-light">
              Or Be Classical
            </div> -->

            <v-form @keyup.native.enter="connexion()">
              <v-text-field
                v-model="courriel"
                color="secondary"
                :label="$t('general.courriel')"
                prepend-icon="mdi-email"
                data-cy="tfUsager"
                @keyup.enter="connexion()"
              />

              <v-text-field
                v-model="motdepasse"
                class="mb-8"
                color="secondary"
                :label="$t('general.mot_de_passe')"
                prepend-icon="mdi-lock-outline"
                type="password"
                data-cy="tfMotPasse"
                @keyup.enter="connexion()"
              />
            </v-form>

            <pages-btn
              type="submit"
              large
              color=""
              depressed
              class="v-btn--text success--text"
              data-cy="btnConnexion"
              @click="connexion()"
            >
              {{ $t('connexion.allez_hop_!') }}
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <v-dialog
      v-model="diaAuthNOK"
      max-width="300"
    >
      <v-card>
        <v-card-title>
          <p>
            Zute !
          </p>
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="diaAuthNOK = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-4 pt-4 text-center">
          <p>
            Votre identifiant ou votre mot de passe est incorrect
          </p>

          <v-btn
            class="mr-3"
            data-cy="btnConnexionInvalideOk"
            @click="diaAuthNOK = false"
          >
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import toucanService from '@/services/toucanService.js'

  export default {
    name: 'Connexion',

    components: {
      PagesBtn: () => import('../pages/components/Btn'),
    },

    data: () => ({
      courriel: '',
      motdepasse: '',
      diaAuthNOK: false,
    }),
    methods: {
      connexion () {
        const data = { username: this.courriel, password: this.motdepasse, rememberMe: false }

        this.$store.commit('SET_JWTTOKEN', '')

        restApiService.post('/api/authenticate', data)
          .then(result => {
            this.$store.commit('SET_JWTTOKEN', result.data.id_token)

            restApiService.get('/api/webUser/infoUsager').then(response => {
              toucanService.connexion(response.data)
            }).catch((erreur) => {
              alert(erreur)
            })
          })
          .catch((erreur) => {
            // alert(erreur)
            this.diaAuthNOK = true
          })
      },
    },
  }
</script>
