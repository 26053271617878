import store from '../store'
import restApiService from '@/services/restApiService.js'
import horlogeService from '@/services/horlogeService.js'
import router from '../router'

const toucanService = {

  connexion(info, returnTo, idEvenement) {
    store.commit('SET_USERINFO', info.usager)
    store.commit('SET_COMPTECLIENT', info.listeComptesClients[0])
    store.commit('SET_KIOSQUE', info.listeKiosques[0])
    this.allerPresentationsSinonSalleAccueil(returnTo, idEvenement)
  },
  authentification(data) {
    store.commit('SET_JWTTOKEN', '')
    restApiService.post('/api/authenticate', data)
      .then(result => {
        store.commit('SET_JWTTOKEN', result.data.id_token)

        restApiService.get('/api/webUser/infoUsager').then(response => {
          store.commit('SET_USERINFO', response.data.usager)
          store.commit('SET_COMPTECLIENT', response.data.listeComptesClients[0])
        }).catch((erreur) => {
          alert(erreur)
        })
      })
      .catch((erreur) => {
        // alert(erreur)
        this.diaAuthNOK = true
      })
  },

  déconnexion() {
    store.commit('SET_EVENEMENT_ACTIF', null)
    store.commit('SET_JWTTOKEN', null)
    store.commit('SET_USERINFO', null)
    store.commit('SET_COMPTECLIENT', null)
    store.commit('SET_KIOSQUE', null)
  },
  allerPresentationsSinonSalleAccueil(returnTo, idEvenement) {
    /* est-ce qu'il y a des évènements accessibles ? */
    if (sessionStorage.getItem('redirectPath')) {
      window.location.href = sessionStorage.getItem('redirectPath')
      sessionStorage.removeItem('redirectPath')
    } else {
      restApiService
        .get('/api/webUser/listeEvenementsAccessibles')
        .then((result) => {
          const m = horlogeService.maintenant()
          const listeInfoEvenements = result.data
          const r = listeInfoEvenements
            .filter(
              i => {
                const dateDebut = Date.parse(i.dateDebut)
                const dateFin = Date.parse(i.dateFin)
                return dateDebut <= m && dateFin > m
              })
            .sort(
              (a, b) => ((a > b) ? 1 : -1),
            )
          if (r.length === 0) {
            if (returnTo) {
              router.push({ path: returnTo })
            } else {
              router.push({ path: '/orka/salleaccueil' })
            }
          } else {
            let id = r[0].id
            if (idEvenement) {
              try {
                id = parseInt(idEvenement)
              } catch (error) {
                id = r[0].id
              }

              const evenement = r.find(eve => eve.id === id)
              if (!evenement) {
                id = r[0].id
              }
            }

            restApiService
              .get('/api/evenements/' + id).then((res) => {
              store.commit('SET_EVENEMENT_ACTIF', res.data)
              if (returnTo) {
                router.push({ path: returnTo })
              } else {
                router.push({ path: `/orka/eve/${res.data.id}/sallepresentations/` })
              }
            })
          }
        })
        .catch((erreur) => alert(erreur))
    }
  },
  async getCommand(url) {
    var result = await restApiService.get(url)
    return result
  },
}
window.toucanService = toucanService
export default toucanService
