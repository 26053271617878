import restApiService from '@/services/restApiService.js'
import moment from 'moment'

export const name = 'horlogeService'

export let delta = 0

const horlogeService = {

  synchroniserServeur() {
    restApiService.get('/api/usagers/maintenant').then((result) => {
      this.heureServeur = moment(result.data.date)
      this.offset = this.heureServeur.diff(new Date())
    }).catch(err => {
      console.warn('Erreur à la synchro de l\'heure', err)
    })
  },

  maintenant() {
    return new Date(new Date().getTime() + (typeof this.offset !== 'undefined' ? this.offset : 0) + delta)
  },

  setDelta(d) {
    delta = d
  },
}

window.horlogeService = horlogeService
export default horlogeService
